import styled from 'styled-components'
import { theme } from '@shared/config'

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${theme.spacings.md3}px;

  > :not(:last-child) {
    padding-bottom: ${theme.spacings.lg1}px;
  }

  @media (min-width: ${theme.breakpoints.tablet}px) {
    width: 576px;
  }
`

const Container = styled.div`
  margin-top: ${theme.spacings.xl4}px;
  border-top: ${theme.borders.basic};
`

const LinkGrid = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${theme.spacings.xs3}px;

  @media (min-width: ${theme.breakpoints.tablet}px) {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    column-gap: ${theme.spacings.lg3}px;
  }
`

export const Styled = {
  Container,
  LinkGrid,
  BodyContainer,
}
